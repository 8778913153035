import React, { HTMLAttributes } from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { StyledSelect } from '../../styles';
import { SelectOption } from '../../../../../hub/shared/EmailOptInForm/components';
import { PropsWithTestId } from '../../../../../../types';

export interface SelectProps extends HTMLAttributes<HTMLSelectElement> {
  blankOption?: string;
  name: string;
  hasError?: boolean;
  placeholder?: string;
  rules?: RegisterOptions;
  options: SelectOption[];
}

export function Select(props: PropsWithTestId<SelectProps>) {
  const {
    name,
    hasError,
    options,
    rules,
    children,
    placeholder,
    testId,
    ...rest
  } = props;

  const { register } = useFormContext();
  return (
    <StyledSelect
      {...rest}
      hasError={hasError}
      id={name}
      {...register(name, rules)}
      data-testid={testId}
    >
      <option value="initial">{placeholder}</option>
      {options?.length
        ? options.map(({ value, label }) => (
            <option value={value} key={value}>
              {label}
            </option>
          ))
        : children}
    </StyledSelect>
  );
}
