import React, { HTMLAttributes } from 'react';
import { renderStyledElementStyles } from '../../../../helpers';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { StyledInput } from '../../styles';
import { PropsWithTestId } from '../../../../../../types';
import styled from 'styled-components';

export interface TextFieldProps extends HTMLAttributes<HTMLInputElement> {
  name: string;
  type: string;
  hasError?: boolean;
  placeholder?: string;
  rules?: RegisterOptions;
}

export const TextFieldWrapper = styled.div<{ type: string }>`
  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.vStyles.elements.form?.elements?.inputField,
    )}
  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.dStyles.elements.form?.elements?.textField,
    )}
  ${({ type }) => (type === 'hidden' ? { marginTop: 0 } : null)}
`;

export const TextField = (props: PropsWithTestId<TextFieldProps>) => {
  const { type, name, hasError, placeholder, rules, testId } = props;
  const { register } = useFormContext();

  return (
    <TextFieldWrapper type={type} data-testid={testId}>
      <StyledInput
        type={type}
        placeholder={placeholder}
        hasError={hasError}
        {...register(name, rules)}
      />
    </TextFieldWrapper>
  );
};

TextField.defaultProps = {
  hasError: false,
  testId: 'contact-us-text-field',
};
