import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';

export const SussessIcon = styled.div`
  position: relative;
  display: block;
  width: 54px;
  height: 54px;
  margin: 0 auto 20px;
  border-radius: 50%;

  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.vStyles.elements.postMessage?.elements?.icon,
    )}

  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.vStyles.elements.postMessage?.elements?.icon?.elements
        ?.success,
    )}
  
  &::before {
    content: '';
    position: absolute;
    transform: rotate(-45deg);
    right: 50%;
    top: 50%;
    width: 16px;
    height: 7px;
    margin-right: -8px;
    margin-top: -5px;

    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module.vStyles.elements.postMessage?.elements?.icon?.elements
          ?.success?.elements?.before,
      )}
  }

  &::after {
    position: absolute;
    content: '';

    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module.vStyles.elements.postMessage?.elements?.icon?.elements
          ?.success?.elements?.after,
      )}
  }
`;

export const ErrorIcon = styled.div`
  position: relative;
  display: block;
  width: 54px;
  height: 54px;
  margin: 0 auto 20px;
  border-radius: 50%;

  ${(props) =>
    renderStyledElementStyles(
      props.theme,
      props.theme.module.vStyles.elements.postMessage?.elements?.icon,
    )}

  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.vStyles.elements.postMessage?.elements?.icon?.elements
        ?.error,
    )}
  
  &::before {
    content: '';
    position: absolute;
    transform: rotate(-45deg);
    right: 50%;
    top: 50%;
    width: 15px;
    height: 2px;
    margin-right: -7px;
    margin-top: -1px;

    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module.vStyles.elements.postMessage?.elements?.icon?.elements
          ?.error?.elements?.before,
      )}
  }

  &::after {
    position: absolute;
    content: '';
    width: 15px;
    height: 2px;
    top: 50%;
    right: 50%;
    margin-right: -7px;
    margin-top: -1px;
    transform: rotate(45deg);

    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module.vStyles.elements.postMessage?.elements?.icon?.elements
          ?.error?.elements?.after,
      )}
  }
`;
