import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Heading } from '../../../sharedComponents';
import { renderHTMLString } from '../../../../../../helpers';
import { ERROR, SUBSCRIBED } from '../../constants';
import { ContactUsPostMessage } from '../ContactUsPostMessage';
import ContactUsForm from '../ContactUsForm';
import { renderStyledElementStyles } from '../../../../helpers';
import { Text } from '../../styles';
import { ContactUsModuleType } from '../../types';

export interface ContactUsContentProps extends ContactUsModuleType {
  cookieName: string;
  sapProductCode: string;
  subscriptionStatus: string;
  setSubscriptionStatus: (status: string) => void;
}

export const HeadingWrapper = styled.div`
  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.module.dStyles.elements.heading)}
  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.module.vStyles.elements.heading)}
`;

export const ContactUsContent = (props: ContactUsContentProps) => {
  const {
    title,
    body,
    cookieName,
    confirmationMessage,
    subscriptionStatus,
    setSubscriptionStatus,
  } = props;

  const themeConfig = useContext(ThemeContext);
  const dStyles = themeConfig.module.dStyles;
  const { xs, md, offset } = dStyles.contentGrid;
  const isSubmitted = subscriptionStatus === SUBSCRIBED;
  const isError = subscriptionStatus === ERROR;

  return isSubmitted || isError ? (
    <ContactUsPostMessage
      confirmationMessage={confirmationMessage}
      isError={isError}
    />
  ) : (
    <div className="container">
      <div className="row">
        <div className={`col-xs-${xs} col-md-${md} col-md-offset-${offset}`}>
          <div className="text-center">
            {title && (
              <HeadingWrapper>
                <Heading>{renderHTMLString(title)}</Heading>
              </HeadingWrapper>
            )}
            {body && <Text>{renderHTMLString(body)}</Text>}
          </div>
        </div>
      </div>
      <div className="row">
        <div className={`col-xs-${xs}`}>
          <ContactUsForm
            {...props}
            cookieName={cookieName}
            setSubscriptionStatus={setSubscriptionStatus}
          />
        </div>
      </div>
    </div>
  );
};
