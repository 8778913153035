import React, { useContext, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { useCookies } from 'react-cookie';
import { SiteBuilderModule } from '../sharedComponents';
import { ContactUsModuleType } from './types';
import { useRootSelector } from '../../../../store/hooks';
import { selectSiteHeader } from '../../../../store/features/siteHeader';
import { ERROR, SUBSCRIBED } from './constants';
import { ContactUsContent } from './components';
import { renderStyledElementStyles } from '../../helpers';

export interface ContactUsModuleProps {
  section: ContactUsModuleType;
}

export const ModuleWrapper = styled.div`
  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.siteBuilderModules.contactUsModule)}

  ${({ theme }) => renderStyledElementStyles(theme, theme.module.vStyles)}
  ${({ theme }) => renderStyledElementStyles(theme, theme.module.dStyles)}
`;

const ContactUsModule = (props: ContactUsModuleProps) => {
  const { section: { sapProductCode } = {} } = props;

  const [cookies] = useCookies();
  const {
    data: { sapProductCode: sapProductCodeHead, emailUpdatesEnabled },
  } = useRootSelector(selectSiteHeader);

  const themeConfig = useContext(ThemeContext);

  const { displayStyles: dStyles, visualStyles: vStyles } =
    themeConfig.siteBuilderModules.contactUsModule;

  const [subscriptionStatus, setSubscriptionStatus] = useState<string>('');

  const sapProductKey = sapProductCode || sapProductCodeHead;
  const acceptedSAPCodes = cookies['contact_us'] || [];
  const moduleAccepted = acceptedSAPCodes.includes(sapProductKey);

  const hideModule =
    moduleAccepted &&
    subscriptionStatus !== SUBSCRIBED &&
    subscriptionStatus !== ERROR;

  if (hideModule || !emailUpdatesEnabled) {
    return null;
  }

  return (
    <SiteBuilderModule
      section={props.section}
      moduleTheme={{ dStyles, vStyles }}
    >
      <ModuleWrapper
        data-testid="contact-us-module-wrapper"
        className="contact-us-module"
      >
        <ContactUsContent
          {...props.section}
          cookieName="contact_us"
          sapProductCode={sapProductKey}
          setSubscriptionStatus={setSubscriptionStatus}
          subscriptionStatus={subscriptionStatus}
        />
      </ModuleWrapper>
    </SiteBuilderModule>
  );
};

export default ContactUsModule;
