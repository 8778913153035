import React, { useContext, useState } from 'react';
import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import styled, { ThemeContext } from 'styled-components';
import Api from '../../../../../../api/LeadGenService/v1';
import Patterns from '../../../../../../utils/pattern/patterns';
import { ERROR, SUBSCRIBED } from '../../constants';
import { CallToAction } from '../../../sharedComponents';
import { TextField } from '../TextField';
import { StyledCallToAction } from '../../styles';
import { getStateFieldLabel } from '../../../../../paymentJourney/DelegateDetailsForm/sharedComponents/DistanceLearningForm';
import { Select } from '../Select';
import {
  countriesList,
  statesList,
} from '../../../../../../services/Dictionary';
import { useCookies } from 'react-cookie';
import { useRootSelector } from '../../../../../../store/hooks';
import { selectPageConfig } from '../../../../../../store/features/pageConfig';
import { renderStyledElementStyles } from '../../../../helpers';
import { CheckboxField } from '../CheckboxField';
import { ContactUsBodyPayload, ContactUsModuleType } from '../../types';
import { selectSiteHeader } from '../../../../../../store/features/siteHeader';

export interface ContactUsFormProps extends ContactUsModuleType {
  cookieName: string;
  setSubscriptionStatus: (status: string) => void;
}

export const PrivacyPolicyText = styled.p`
  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.vStyles.elements.privacyPolicyText,
    )}
  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.dStyles.elements.privacyPolicyText,
    )}
`;

export const CheckboxGroup = styled.div`
  margin-top: 20px;
  margin-left: -10px;

  [dir='rtl'] & {
    margin-right: 0;
  }
`;

export const StyledForm = styled.form`
  &&& {
    max-width: 800px;
    margin: 12px auto;
    text-align: center;
  }
`;

export const StyledLink = styled.a`
  color: inherit;
`;

const ContactUsForm = (props: ContactUsFormProps) => {
  const {
    cookieName,
    sapProductCode,
    buttonText,
    setSubscriptionStatus,
    enableAttendingQuestion,
    enableSponsoringQuestion,
    enableSpeakingQuestion,
    includeCompanyName,
    includeJobTitle,
    includePhone,
  } = props;

  const [cookies, setCookies] = useCookies();
  const { cookieSitePath } = useRootSelector(selectPageConfig);
  const themeConfig = useContext(ThemeContext);

  const {
    data: { title = '', nameFormat },
  } = useRootSelector(selectSiteHeader);

  const {
    elements: {
      callToAction: { settings: { size: ctaSize = '' } = {} } = {},
    } = {},
  } = themeConfig.module.dStyles.elements.form;

  const { i18n, t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const methods = useForm<ContactUsBodyPayload>({
    mode: 'onSubmit',
  });

  const {
    handleSubmit,
    watch,
    formState: { errors },
  } = methods;

  const selectedCountry = watch('country');

  const countriesOptions = Object.keys(countriesList).map((key) => {
    return {
      value: key,
      label: t(countriesList[key]),
    };
  });

  const statesOptions =
    statesList[selectedCountry] &&
    Object.keys(statesList[selectedCountry]).map((key) => {
      return {
        value: key,
        label: t(statesList[selectedCountry][key]),
      };
    });

  const submitHandler = async function (values: ContactUsBodyPayload) {
    setLoading(true);

    const response = await fetch(Api.subscribe('contact-form'), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
      },
      body: JSON.stringify({ ...values, productCode: sapProductCode }),
    });

    setLoading(false);

    if (response.status !== 200) {
      setSubscriptionStatus(ERROR);
      return;
    }

    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 7);

    const acceptedSAPCodes = cookies['contact_us'] || [];
    const updatedAcceptedSAPCodes = [...acceptedSAPCodes, sapProductCode];

    setCookies(cookieName, JSON.stringify(updatedAcceptedSAPCodes), {
      expires: expirationDate,
      path: cookieSitePath,
      secure: true,
    });
    setSubscriptionStatus(SUBSCRIBED);
  };

  const rowClassNames = classNames('row', {
    reverse: !includePhone && (includeCompanyName || includeJobTitle),
  });

  const firstNamePlaceholder = t(
    `global.name-format.${nameFormat}.firstname.label`,
  );
  const lastNamePlaceholder = t(
    `global.name-format.${nameFormat}.lastname.label`,
  );

  return (
    <FormProvider {...methods}>
      <StyledForm
        name="contactUsForm"
        role="form"
        onSubmit={handleSubmit(submitHandler)}
        noValidate
      >
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <TextField
              type="text"
              name="firstName"
              placeholder={`${firstNamePlaceholder} *`}
              rules={{
                required: true,
              }}
              hasError={!!errors?.firstName}
            />
          </div>
          <div className="col-xs-12 col-md-6">
            <TextField
              type="text"
              name="lastName"
              placeholder={`${lastNamePlaceholder} *`}
              rules={{
                required: true,
              }}
              hasError={!!errors?.lastName}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <TextField
              type="email"
              name="email"
              placeholder={t('site.contact-us.email.placeholder')}
              rules={{
                required: true,
                validate: (value: string) => Patterns.isEmail(value),
              }}
              hasError={!!errors?.email}
            />
          </div>
          <div className="col-xs-12 col-md-6">
            <Select
              name="country"
              options={countriesOptions}
              placeholder={t('site.contact-us.country.placeholder')}
              rules={{
                required: true,
              }}
              hasError={!!errors?.country}
              testId="contact-us-country-select"
            />
          </div>
        </div>
        <div className={rowClassNames}>
          {statesList[selectedCountry] && (
            <div className="col-xs-12 col-md-6 hidden-md hidden-lg hidden-xl">
              <Select
                name="state"
                options={statesOptions}
                placeholder={t(getStateFieldLabel(selectedCountry))}
                rules={{
                  required: true,
                }}
                hasError={!!errors?.state}
                testId="contact-us-state-select"
              />
            </div>
          )}
          {includePhone && (
            <div className="col-xs-12 col-md-6">
              <TextField
                type="text"
                name="phoneNumber"
                placeholder={t('site.contact-us.phone-number.placeholder')}
                rules={{
                  required: true,
                  validate: {
                    isValid(value) {
                      return !value || Patterns.isTelephoneNumber(value)
                        ? true
                        : 'Please enter a valid telephone number';
                    },
                  },
                }}
                hasError={!!errors?.phoneNumber}
                testId="contact-us-phone-text-field"
              />
            </div>
          )}
          {statesList[selectedCountry] && (
            <div className="col-xs-12 col-md-6 hidden-xs hidden-sm">
              <Select
                name="state"
                options={statesOptions}
                placeholder={t(getStateFieldLabel(selectedCountry))}
                rules={{
                  required: true,
                }}
                hasError={!!errors?.state}
                testId="contact-us-state-select"
              />
            </div>
          )}
          {includeCompanyName && (
            <div className="col-xs-12 col-md-6">
              <TextField
                type="text"
                name="company"
                placeholder={t('site.contact-us.company.placeholder')}
                rules={{
                  required: true,
                }}
                hasError={!!errors?.company}
                testId="contact-us-company-text-field"
              />
            </div>
          )}
          {includeJobTitle && (
            <div className="col-xs-12 col-md-6">
              <TextField
                type="text"
                name="jobTitle"
                placeholder={t('site.contact-us.job-title.placeholder')}
                rules={{
                  required: true,
                }}
                hasError={!!errors?.jobTitle}
                testId="contact-us-job-text-field"
              />
            </div>
          )}
        </div>

        <CheckboxGroup>
          <div className="row">
            <div className="col-xs-12">
              {enableAttendingQuestion && (
                <CheckboxField
                  name="interestedInAttending"
                  label={t('site.contact-us.interested-in-attending.label')}
                  testId="contact-us-attending-checkbox-field"
                />
              )}
              {enableSponsoringQuestion && (
                <CheckboxField
                  name="interestedInSponsoring"
                  label={t('site.contact-us.interested-in-sponsoring.label')}
                  testId="contact-us-sponsoring-checkbox-field"
                />
              )}
              {enableSpeakingQuestion && (
                <CheckboxField
                  name="interestedInSpeaking"
                  label={t('site.contact-us.interested-in-speaking.label')}
                  testId="contact-us-speaking-checkbox-field"
                />
              )}
            </div>
          </div>
        </CheckboxGroup>

        <PrivacyPolicyText data-testid="privacy-policy-text">
          {t('site.contact-us.privacy-policy.definition', {
            buttonText,
            siteName: title,
          })}
        </PrivacyPolicyText>
        <PrivacyPolicyText data-testid="privacy-policy-text">
          <Trans
            i18nKey="site.contact-us.privacy-policy.explanation"
            values={{
              privacy: t('site.email-opt-in.privacy-policy'),
            }}
          >
            <StyledLink
              href={'https://www.informa.com/privacy-policy/'}
              target="_blank"
            >
              {t('site.contact-us.privacy-policy')}
            </StyledLink>
          </Trans>
        </PrivacyPolicyText>

        <StyledCallToAction>
          <CallToAction
            type="submit"
            size={ctaSize}
            label={buttonText}
            loading={loading}
            className
            testId="cta-submit-subscription"
            ctaConfig
          />
        </StyledCallToAction>
      </StyledForm>
    </FormProvider>
  );
};

export default ContactUsForm;
