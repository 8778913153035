import styled from 'styled-components';

export const Checkbox = styled.label`
  position: relative;
  display: flex;
  padding: 6px 0px 6px 36px;
  margin: 0;

  :not(:disabled) {
    cursor: pointer;
  }

  input {
    position: absolute;
    z-index: -1;

    :not(:disabled) {
      cursor: pointer;
    }
  }

  span {
    position: relative;
    font-size: 16px;
    user-select: none;
    text-align: left;
    padding: 2px 35px 2px 35px;

    ::before {
      content: '';
      position: absolute;
      width: 24px;
      height: 24px;
      top: 50%;

      [dir='ltr'] & {
        left: 0px;
      }

      [dir='rtl'] & {
        right: 0px;
      }

      margin-top: -13px;
      border: 2px solid #fff;
      border-radius: 2px;
      background-color: transparent;
    }
  }

  input:checked + span {
    ::before {
      background-color: transparent;
    }

    ::after {
      content: '';
      position: absolute;
      width: 12px;
      height: 6px;
      top: 50%;
      margin-top: -5px;
      [dir='ltr'] & {
        transform: rotate(-45deg);
        left: 6px;
      }

      [dir='rtl'] & {
        transform: rotate(-45deg);
        right: 6px;
      }

      border-left: 2px solid #fff;
      border-bottom: 2px solid #fff;
    }
  }
`;
