import React from 'react';
import { useTranslation } from 'react-i18next';
import { Paragraph } from '../../../sharedComponents';
import { Text, SussessIcon, ErrorIcon } from '../../styles';
import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';

export interface ContactUsPostMessageProps {
  confirmationMessage?: string;
  isError: boolean;
}

export const PostMessageWrapper = styled.div`
  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.module.vStyles.elements.postMessage)}
  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.module.dStyles.elements.postMessage)}
`;

export const ContactUsPostMessage = (props: ContactUsPostMessageProps) => {
  const { t } = useTranslation();
  const { confirmationMessage, isError } = props;

  return (
    <PostMessageWrapper data-testid="contact-us-post-message">
      {isError ? (
        <ErrorIcon data-testid="error-icon" />
      ) : (
        <SussessIcon data-testid="success-icon" />
      )}
      <Text>
        {isError ? (
          <Paragraph
            testId="error-confirmation-message"
            className
            content={t('site.contact-us.error.description')}
            alignStyle="CENTRE"
          />
        ) : (
          <Paragraph
            className
            testId="success-confirmation-message"
            content={confirmationMessage}
            alignStyle="CENTRE"
          />
        )}
      </Text>
    </PostMessageWrapper>
  );
};
