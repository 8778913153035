import React, { HTMLAttributes } from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { Checkbox } from '../../styles';
import { PropsWithTestId } from '../../../../../../types';

export interface CheckboxFieldProps extends HTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
  rules?: RegisterOptions;
}

export const CheckboxField = (props: PropsWithTestId<CheckboxFieldProps>) => {
  const { name, label, rules, testId } = props;
  const { register } = useFormContext();

  return (
    <Checkbox data-testid={testId}>
      <input type="checkbox" {...register(name, rules)} />
      <span className="label">{label}</span>
    </Checkbox>
  );
};

CheckboxField.defaultProps = {
  testId: 'contact-us-checkbox-field',
};
